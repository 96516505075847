<template>
  <b-card-code>
    <div class="row mb-2">
      <div class="col-lg-6">
        <h4>Rendelések</h4>
      </div>
      <div class="col-lg-6 text-right">
        <button class="btn btn-primary mr-0 mr-md-2" @click="$router.push( {name:'monolit.order.list.list'} )">Futó rendelések</button>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'inside_order'"
        >
          <span v-if="props.row.inside_order == 1">Belsős rendelés</span>
          <span v-else-if="props.row.inside_order == 0">Külsős rendelés</span>
          <span v-else-if="props.row.inside_order == 2">Raktár feltöltés</span>
        </div>

        <div
          v-else-if="props.column.field === 'accepted'"
        >
          <span class="badge badge-success" style=";width:150px;color:white" v-if="props.row.accepted == true">Elfogadva</span>
          <span class="badge badge-danger" style=";width:150px;color:white" v-else-if="props.row.disabled == true">Elutasítva</span>
          <span class="badge badge-warning" style=";width:150px;color:white" v-else>Feldolgozás alatt</span>
        </div>

        <div
          v-else-if="props.column.field === 'accepted_warehouse'"
        >
          <div v-if="props.row.inside_order !== 2">
            <span class="badge badge-success" style=";width:150px;color:white" v-if="props.row.accepted_warehouse == true">Kiküldve</span>
            <span class="badge badge-danger" style=";width:150px;color:white" v-else-if="props.row.disabled_warehouse == true">Elutasítva</span>
            <span class="badge badge-warning" style=";width:150px;color:white" v-else>Feldolgozás alatt</span>
          </div>
        </div>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge style=";width:150px;color:white" :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item v-b-modal.modal-edit @click="loadOrderData(props.row.id)">
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50"
                />
                <span>Feldolgozás</span>
              </b-dropdown-item>
              <b-dropdown-item @click="changeArchiveStatus(props.row.id)">
                <feather-icon
                  icon="FolderIcon"
                  class="mr-50"
                />
                <span>Visszaállítás</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Mutasd 1-től
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal id="modal-edit" size="lg" ref="modal-edit" title="Rendelés adatai" hide-footer>
      <div class="row">
        <div class="col-lg-12 mt-2">
          <div class="row">
            <div class="col-lg-6">
              <p class="mb-0">Megrendelő neve: {{ selected.creator_name }}</p>
              <p class="mb-0">Projekt: {{ selected.project_name }}</p>
              <p class="mb-0" v-if="selected.inside_order !== 2">Szállítás helye: {{ selected.place }}</p>
              <p class="mb-0" v-if="selected.inside_order !== 2">Rendelést átveszi: {{ selected.receiver_name }}</p>
              <p class="mb-0" v-if="selected.inside_order !== 2">Mikorra szeretné: {{ selected.order_date }}</p>
              <p class="mb-0" v-if="selected.order_deliver_started">Várható kiszállítás: {{ selected.order_deliver_started_date }}</p>
              <p class="mb-0">Megjegyzés: {{ selected.comment }}</p>
            </div>
            <div class="col-lg-6" v-show="selected.inside_order == 0">
              <div v-if="checkID(selected.target_id)">
                <b-form-group
                  label="Aktuális felelős"
                  label-for="order-project"
                >
                  <b-form-select
                    id="order-project"
                    v-model="target_id"
                    :options="targetOptions"
                    text-field="name"
                    value-field="id"
                  />
                </b-form-group>
                <p class="float-right">
                  <button class="btn btn-primary btn-sm" @click="changeTargetID()">Módosítás</button>
                </p>
              </div>
              <div v-else>Aktuális felelős<br><span class="badge badge-info">{{selected.target_name}}</span>
              </div>
            </div>
          </div>

          <p class="mt-3">
            <b>Rendelt termékek</b>
          </p>
          <div id="print_div">
            <table class="table mb-2">
              <thead>
                <tr>
                  <th scope="col">Termék</th>
                  <th scope="col">Mennyiség</th>
                  <th scope="col">Mennyiségi egység</th>
                  <th class="text-center" v-if="selected.accepted == true && selected.accepted_warehouse == true" scope="col">Teljesítve</th>
                </tr>
              </thead>
              <tr v-for="(product, index) in selected.items" :key="index">
                <td>
                  {{ product.product }}
                </td>
                <td>
                  {{ product.quantity }}
                </td>
                <td>
                  {{ product.unit }}
                </td>
                <td class="text-center" v-if="selected.accepted == true && selected.accepted_warehouse == true">
                  <span class="badge badge-success" style="cursor:pointer" v-if="product.done"><feather-icon
                    icon="CheckCircleIcon"
                    size="18"
                  /></span>
                  <span class="badge badge-danger" style="cursor:pointer;max-width:150px;color:white" v-else><feather-icon
                    icon="MinusCircleIcon"
                    size="18"
                  /></span>
                </td>
              </tr>
            </table>
          </div>
          <button class="btn btn-primary float-right"  @click="print()">Rendelt termékek nyomtatása</button>
          <div v-show="selected.inside_order == 0">
            <p class="mt-5 pt-5">
              <b>Árajánlatok</b>
            </p>
            <div class="row">
              <div class="col-lg-2">Cég név</div>
              <div class="col-lg-3">Dátum</div>
              <div class="col-lg-2 text-right">Ár</div>
              <div class="col-lg-3">Rögzítette</div>
              <div class="col-lg-2">Elfogadás</div>
            </div>
            <hr>
            <div v-if="selected.files" v-for="(file, index) in selected.files" :key="index" class="row pt-1 pb-1">
              <div class="col-lg-2"><u style="cursor:pointer" @click="downloadFile(selected.id, index)">{{ file.company_name }}</u></div>
              <div class="col-lg-3">{{ file.created_at}}</div>
              <div class="col-lg-2 text-right">{{ parseInt(file.company_price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</div>
              <div class="col-lg-3">{{ file.creator_name}}</div>
              <div class="col-lg-2" v-if="!file.accepted && !file.declined">
                <div v-if="checkRole('accept_order')">
                  <span class="badge badge-success mr-1" style="cursor:pointer" @click="changeOfferStatus(index, true)"><feather-icon
                    icon="CheckCircleIcon"
                    size="18"
                  /></span>
                  <span class="badge badge-danger" style="cursor:pointer" @click="changeOfferStatus(index, false)"><feather-icon
                    icon="MinusCircleIcon"
                    size="18"
                  /></span>
                </div>
                <div v-else>
                  <span class="badge badge-info">
                    Feldolgozás alatt
                  </span>
                </div>
              </div>
              <div class="col-lg-2" v-else>
                <span v-if="file.accepted" class="badge badge-success">Elfogadva</span>
                <span v-else class="badge badge-danger">Elutasítva</span>
              </div>
              <div class="col-lg-12 pr-1 pl-1 pt-1">
                <div class="bg-light-primary pt-1 pl-1 pr-1 pb-1">
                  <div class="row border-bottom-own ml-0 mr-0">
                    <div class="col-lg-5 font-weight-bold text-light">
                      Termék neve
                    </div>
                    <div class="col-lg-3 font-weight-bold text-light">
                      Szükséges
                    </div>
                    <div class="col-lg-3 font-weight-bold text-light">
                      Elérhető
                    </div>
                  </div>
                  <div class="row border-bottom-own ml-0 mr-0" v-for="offer in file.offer_items" :key="offer.product">
                    <div class="col-lg-5 text-light">
                      {{ offer.product }}
                    </div>
                    <div class="col-lg-3 text-light">
                      {{ offer.quantity }} {{ offer.unit }}
                    </div>
                    <div class="col-lg-3 text-light">
                      {{ offer.available }} {{ offer.unit }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div>Még nem érkezett ajánlat</div>
            </div>
            <p class="mt-3">
              <b>Árajánlatok rögzítése</b>
            </p>
            <div class="row pr-1 pl-1" v-if="checkRole('upload_offer')">
              <b-form-input v-model="company_name" class="col-md-4 mr-1" type="text" placeholder="Cég neve"/>
              <b-form-input v-model="company_price" class="col-md-2 mr-1" type="number" placeholder="Ára"/>
              <b-form-file ref="file" class="col-md-3 mr-1" @change="changeFile" id="file" v-model="file" type="file" />
              <button class="btn btn-primary mb-2 col-md-2" @click="addFile(selected.id)">Feltöltés</button>
              <b-form-input v-model="email" class="col-md-12 mb-1" type="text" placeholder="Email az ajánlatról"/>
              <table class="table col-lg-12">
                <thead>
                  <tr>
                    <th scope="col">Termék</th>
                    <th scope="col">Szükséges</th>
                    <th scope="col">Rendelhető mennyiség</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(offer, index) in offerItems" :key="index">
                    <td>
                      {{ offer.product }}
                    </td>
                    <td>
                      {{ offer.quantity }} {{ offer.unit }}
                    </td>
                    <td>
                      <input type="text" placeholder="Elérhető" class="form-control text-center" v-model="offer.available" />
                    </td>
                    <td>
                      <b-form-checkbox v-model="offer.inOffer" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <b-row v-if="materials.length > 0">
            <b-col md="12" class="mt-3"><b>Elérhető tételek a projekthez</b></b-col>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Termék</th>
                  <th scope="col">Mennyiség (elérhető / összesen)</th>
                  <th scope="col">Mértékegység</th>
                </tr>
              </thead>
              <tr v-for="(material, index) in materials" :key="index">
                <td>
                  {{ material.product }}
                </td>
                <td>
                  {{ material.quantity }} / {{ material.total }}
                </td>
                <td>
                  {{ material.unit }}
                </td>
              </tr>
            </table>
          </b-row>
          <p class="mt-3">
            <b>Megjegyzések</b>
          </p>
          <b-form-group
            label="Megjegyzés írása"
            label-for="write-comment"
          >
            <b-form-textarea
              id="write-comment"
              v-model="new_comment"
            />
          </b-form-group>
          <button class="btn btn-primary mb-2" @click="addComment(selected.id)">Küldés</button>
          <hr>
          <div v-for="(comment, index) in selected.comment_array" :key="index">
            <p><b>{{ comment.user }}</b><br><small>{{ comment.date }}</small><br>{{ comment.comment }}</p>
            <hr>
          </div>
        </div>
      </div>
      <div class="row justify-content-between">
        <div>
          <button
            class="mr-2 btn"
            @click="$bvModal.hide('modal-edit')"
          >
            Bezár
          </button>
        </div>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BBadge, BPagination, BFormSelect, BDropdown, BDropdownItem, BFormGroup, BFormFile, BFormTextarea, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormFile,
    BFormTextarea,
    BFormInput,
    BFormCheckbox,
  },
  data() {
    return {
      materials: [],
      company_name: '',
      company_price: '',
      offerItems: [],
      selected: [],
      targetOptions: [],
      target_id: '',
      pageLength: 25,
      dir: false,
      email: '',
      offers: [],
      file: null,
      new_comment: '',
      columns: [
        {
          label: 'Rendelés típusára',
          field: 'inside_order',
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: 'Projekt neve',
          field: 'project_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés projektre',
          },
        },
        {
          label: 'Helyszín',
          field: 'place',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés helyszínre',
          },
        },
        {
          label: 'Árajánlat elfogadva',
          field: 'accepted',
          filterOptions: {
            enabled: false,
            placeholder: 'Keresés státuszra',
          },
        },
        {
          label: 'Rendelés kiküldve',
          field: 'accepted_warehouse',
          filterOptions: {
            enabled: false,
            placeholder: 'Keresés státuszra',
          },
        },
        {
          label: 'Ki szeretné?',
          field: 'creator_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Státusz',
          field: 'status',
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-success',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-info',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.loadOrderList(0)
    this.getTargetOptions()
  },
  methods: {
    print() {
      const prtHtml = document.getElementById('print_div').innerHTML
      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      let selectedFiles = null

      if (this.selected.files != null) {
        selectedFiles = this.selected.files.filter(file => file.accepted === true)
      }

      console.log(selectedFiles)

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <style>
            body{
              font-size: 26px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              text-align:center;
            }
            .mb-0{
              margin-bottom: 0px;
            }
          </style>
        </head>
        <body>
          <div style="background:gray;">
            <p class="mb-0">Megrendelő neve: ${this.selected.creator_name}</p>
            <p class="mb-0">Projekt: ${this.selected.project_name}</p>
            <p class="mb-0">Szállítás helye: ${this.selected.place}</p>
            <p class="mb-0">Rendelést átveszi: ${this.selected.receiver_name}</p>
            <p class="mb-0">Mikorra szeretné: ${this.selected.order_date}</p>
            <p class="mb-0">Megjegyzés: ${this.selected.comment}</p>
            <hr>
          </div>
          ${prtHtml}
        </body>
      </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    changeOfferData(index) {
      this.offerItems[index].inOffer = !this.offerItems[index].inOffer
    },
    checkID(id) {
      return JSON.parse(localStorage.getItem('user')).id === id
    },
    checkRole(roles) {
      return JSON.parse(localStorage.getItem('user')).role.includes(roles)
    },
    downloadFile(id, index) {
      axios({
        method: 'post',
        
        url: `order/download-order/${id}/${index}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }).then(response => {
        const mimeType = response.headers['content-type']
        const extension = mimeType.split('/')[1]
        const blob = new Blob([response.data], { type: mimeType })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `file.${extension}`
        link.click()
      })
    },
    changeItemStatus(index, status) {
      axios({
        method: 'put',
        
        url: `order/change_offer_type/${this.selected.id}/${index}`,
        data: {
          status,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(this.selected.id)
      })
    },
    changeFile(event) {
      this.file = event.target.files[0]
    },
    addFile() {
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('company_name', this.company_name)
      formData.append('company_price', this.company_price)
      this.offerItems.forEach((item) => {
        formData.append('offer_items[]', JSON.stringify(item))
      })
      formData.append('email', this.email)
      axios({
        method: 'post',
        
        url: `order/upload-file/${this.selected.id}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen feltölve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.file = null
        this.company_name = ''
        this.company_price = ''
        this.email = ''
        this.$refs.file.value = null
        this.loadOrderList(this.selected.id)
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    changeTargetID() {
      axios({
        method: 'put',
        
        url: `order/change-target-id/${this.selected.id}`,
        data: {
          target_id: this.target_id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(this.selected.id)
      })
    },
    changeOfferStatus(index, status) {
      axios({
        method: 'put',
        
        url: `order/change_offer_type/${this.selected.id}/${index}`,
        data: {
          status,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(this.selected.id)
      })
    },
    getTargetOptions() {
      axios({
        method: 'get',
        
        url: 'role/list/order_target',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.targetOptions = res.data
      })
    },
    changeArchiveStatus(id) {
      axios({
        method: 'put',
        
        url: `order/archive/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.loadOrderList(0)
      })
    },
    loadOrderList(id) {
      axios({
        method: 'get',
        
        url: 'order/list/archived',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.rows = response.data
        if (id !== 0) {
          this.selected = this.rows.find(item => item.id == id)
          this.target_id = this.selected.target_id
          this.offerItems = this.selected.items
          this.offerItems.forEach(item => {
            item.available = 0
            item.inOffer = false
          })
        }
      })
    },
    loadOrderData(id) {
      this.selected = this.rows.find(row => row.id === id)
      this.target_id = this.selected.target_id
      this.offerItems = this.selected.items
      this.offerItems.forEach(item => {
        item.available = 0
        item.inOffer = false
      })
      axios({
        method: 'get',
        url: `order/available-materials/${this.selected.product_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.materials = res.data
      })
    },
    disabled(id) {
      axios({
        method: 'get',
        
        url: `order/warehouse/disable/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(0)
        this.$bvModal.hide('modal-edit')
      })
    },
    addComment(id) {
      if (this.new_comment == '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Adjon meg kommentet',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      } else {
        axios({
          method: 'post',
          
          url: `order/new-comment/${id}`,
          data: {
            comment: this.new_comment,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).then(response => {
          this.selected.comment_array = response.data
          this.new_comment = ''
        })
      }
    },
    accepted(id) {
      axios({
        method: 'get',
        
        url: `order/warehouse/accept/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(0)
        this.$bvModal.hide('modal-edit')
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style>
  .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after{
    content: "►" !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before{
    content: "◄" !important;
  }
  .border-bottom-own{
    border-bottom: 1px solid #7a7a7a !important;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .bg-light-primary{
    background-color: #343D55 !important;
    border-radius: 10px;
  }
</style>
